<template>
  <v-card>
    <div class="tw-px-2 md:tw-px-6">
      <card-title icon="mdi-magnify" :is-image="false"
        >Choose the next step in your process</card-title
      >
    </div>
    <div
      class="
        tw-px-8
        md:tw-px-12
        tw-grid tw-grid-cols-1
        md:tw-grid-cols-3
        tw-gap-4
        md:tw-gap-8
        tw-mt-3
      "
    >
      <template v-for="(serviceProvider, i) in serviceProviders">
        <div v-if="viewPermissions(serviceProvider.roles)" :key="i">
          <v-card class="mt-5 mb-5 text-center" elevation="3" height="330">
            <br />
            <v-img
              :src="serviceProvider.src"
              class="mx-auto my-5"
              width="70"
              height="auto"
            />
            <h4>{{ serviceProvider.heading }}</h4>
            <v-card-text
              ><p>
                {{ serviceProvider.text }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="mx-auto mb-5 pa-5"
                large
                color="primary"
                :to="serviceProvider.link"
              >
                Proceed
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </template>
    </div>
  </v-card>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import { viewPermissions } from '@/utils/roles'
export default {
  components: { CardTitle },
  data: () => ({
    serviceProviders: [
      {
        src: require('@/assets/img/team-mgmt.png'),
        heading: 'Claims Team Management',
        text: 'Manage system users',
        link: { name: 'TeamTable' },
        roles: ['admin', 'internalAssessor'],
      },
      {
        src: require('@/assets/img/team-mgmt.png'),
        heading: 'Approver Management',
        text: 'Manage approval bands and users',
        link: { name: 'ApproverManagement' },
        roles: ['admin'],
      },
      {
        src: require('@/assets/img/team-mgmt.png'),
        heading: 'TAT Management',
        text: 'Manage Turn Around Time',
        link: { name: 'TATManagement' },
        roles: ['admin'],
      },
    ],
  }),
  methods: {
    viewPermissions,
  },
}
</script>
